import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

const OtherProductSectionStyle = styled(Box)(
  ({ theme }) => `

  .container {
    max-width: 1150px;
    width: 100%;
    margin: 100px auto;
    display: flex;
    flex-direction: column;

    @media (max-width: 1999px) and (min-width: 1701px) {
      max-width: 1440px !important;
    }
    @media (min-width: 2000px) {
      max-width: 1730px !important;
    }
    ${theme.breakpoints.down('lg')} {
      margin: 70px auto;
      padding: 0px 20px;
    }
    ${theme.breakpoints.down('sm')} {
      margin: 50px auto;
      padding: 0px 15px;
    }
  }
`,
);

export const ProductStyle = styled(Box)(
  ({ theme }) => `

  height: 140px;
  background: #FFFFFF;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.160784);
  border-radius: 10px;
  margin-bottom: 16px;

  .logo-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .content-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 0px 8px;
  }

  .button-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  ${theme.breakpoints.down('md')} {
    height: 260px;
    .logo-container {
      justify-content: flex-end;
    }
  
    .content-container {
      align-items: center;
      text-align: center;
    }

    .button-container {
      justify-content: start;
    }
  }
`,
);

export default OtherProductSectionStyle;
