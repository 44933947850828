import styled from '@emotion/styled';
import Button from '@mui/material/Button';

const ButtonStyle = styled(Button)`
  svg {
    font-size: 22px;
  }
`;

export default ButtonStyle;
