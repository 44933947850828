import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'next-i18next';

import OtherProductSectionStyle, { ProductStyle } from './style';

import Button from 'src/components/atoms/button';
import Image from 'src/components/atoms/image';
import NextLink from 'src/components/atoms/link';
import { getAssetUrl } from 'src/helpers/utils';

const PRODUCT_ITEMS = [
  {
    imageUrl: 'SkillLaneB2B-Logo.png',
    title: 'home.section.b2b_title',
    content: 'home.section.b2b_content',
    buttonText: 'home.course_card_view_course_detail',
    url: `${process.env.NEXT_PUBLIC_APP_DOMAIN}/business`,
  },
  {
    imageUrl: 'TUXSA-Logo.png',
    title: 'home.section.tuxsa_title',
    content: 'home.section.tuxsa_content',
    buttonText: 'home.section.tuxsa_button',
    url: `${process.env.NEXT_PUBLIC_APP_DOMAIN}/academic/tuxsa`,
  },
  {
    imageUrl: 'SkillLaneCPD-Logo.png',
    title: 'home.section.cpd_title',
    content: 'home.section.cpd_content',
    buttonText: 'home.course_card_view_course_detail',
    url: `${process.env.NEXT_PUBLIC_APP_DOMAIN}/cpd`,
  },
];

export const Product = ({ product }: ProductProps) => {
  const { t } = useTranslation();
  const { title, url, content, imageUrl, buttonText } = product;

  return (
    <ProductStyle>
      <Grid container height="100%">
        <Grid item xs={12} md={4} className="logo-container">
          <Box width={160}>
            <Image src={getAssetUrl(imageUrl)} alt={t(title)} />
          </Box>
        </Grid>
        <Grid item xs={12} md={5} className="content-container">
          <Typography variant="h4" fontWeight="bold">
            {t(title)}
          </Typography>
          <Typography variant="h6_deprecated" fontWeight="normal" color="#555555" sx={{ fontFamily: 'Lanna' }}>
            {t(content)}
          </Typography>
        </Grid>
        <Grid item xs={12} md={3} className="button-container">
          <NextLink href={url} target="_blank">
            <Button variant="outlined" color="primary" sx={{ width: 140 }}>
              {t(buttonText)}
            </Button>
          </NextLink>
        </Grid>
      </Grid>
    </ProductStyle>
  );
};

const OtherProductSection = () => {
  const { t } = useTranslation();

  return (
    <OtherProductSectionStyle>
      <Box className="container">
        <Typography
          className="title"
          variant="h1"
          color="#222222"
          sx={{ textAlign: 'center', width: '100%', marginBottom: 4 }}
        >
          {t('home.section.product_title')}
        </Typography>
        <Box>
          {PRODUCT_ITEMS.map((product) => (
            <Product product={product} key={product.title} />
          ))}
        </Box>
      </Box>
    </OtherProductSectionStyle>
  );
};

type ProductProps = {
  product: {
    title: string;
    url: string;
    content: string;
    imageUrl: string;
    buttonText: string;
  };
};

export default OtherProductSection;
